import React from "react";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import ConfirmActionModel from "../../studio-store-restaurant-components/src/common/ConfirmModel";
import "../assets/css/filterProducts.css";
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "reactstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { MdKeyboardArrowUp } from "react-icons/md";
import { CircularProgress } from '@material-ui/core';
import ListSkeletonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/ListSkeletonLoader";
import { FoodTagVeg, FoodTagNonVeg, NotWishlisted, Wishlisted, modalCloseIcon, noResultFound } from "./assets";
import _ from "lodash";
import content from "../../studio-store-restaurant-theme/src/AppWebContent";
import ShowMoreText from "react-show-more-text";
import { showTwoDecimalDigit, warnNotification } from "../../studio-store-restaurant-components/src/Utility.web";
import FilterProductController from "./FilterProductController.web";
import { CatalogueType } from "../../utilities/src/types/CommonTypes";
import { withStyles } from '@material-ui/core/styles';

const useStyles = {
  mainWrapper:{
    paddingRight: "8px"
  },

  productList:{
    marginBottom: "24px",
    boxShadow: "0 4px 4px #f5f5f5!important",
    borderRadius: "5px",
    padding: "24px",
    position: "relative" as "relative",
    minHeight: "150px",
    "--bs-bg-opacity": 1,
    backgroundColor: "rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important",
    "@media (max-width: 1279.92px)":{
      padding: "15px",
      marginBottom: "10px",
    },
    "@media (max-width: 768px)":{
      padding: "15px",
      marginBottom: "10px",
      marginTop: "10px",
    },
    "@media (max-width: 500px)":{
      padding: "15px",
      marginBottom: "20px",
      marginTop: "20px",
    },
    "@media (min-width: 1366px) and (max-width: 1900px)":{
      padding: "24px",
      marginBottom: "24px!important",
    },
    "@media (min-width: 1900px)":{
      padding: "24px"
    }
  },

  cartProductInfo:{
    width: "100%",
    "& h2":{
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#000",
      marginBottom: "0",
      maxWidth: "310px",
      textAlign: "left",
      fontStyle: "normal",
      display: "inline-block",
      textTransform: "capitalize",
      "@media (max-width: 768px)":{
        fontSize: "16px"
      }
    },
    "& .food-subtitle":{
      fontSize: "14px",
      color: "#000",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "24px",
      margin: 0,
      "&::-webkit-scrollbar":{
        width: "2px"
      },
      "&::-webkit-scrollbar-thumb":{
        background: "var(--color-gray)"
      },
      "&::-webkit-scrollbar-track":{
        backgroundColor: "#f1f1f1",
        width: "1px",
      }
    }
  },

  vegNonVegDiv:{
    "& img":{
      maxWidth: "100%",
      height: "auto",
    },
    "& span":{
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "16px",
      color: "#000",
    }
  },

  outOfStock:{
    fontSize: "18px",
    color: "#fc5185",
    fontWeight: 500,
  },

  productPrice:{
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "1.08px",
    color: "#000",
    fontStyle: "normal",
    "@media (max-width: 1279.92px)":{
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: ".77px",
      fontWeight: 500,
      fontStyle: "normal",
    },
    "@media (max-width: 768px)":{
      fontSize: "15px",
      lineHeight: "1.2",
    }
  },

  productActualPrice: {
    fontWeight: 500,
    lineHeight: "1.14",
    letterSpacing: "1.08px",
    paddingLeft: "5px",
    fontSize: "16px",
    color: "grey",
    textDecoration: "line-through",
  },

  productDiscount:{
    fontSize: "15px",
    color: "#f67054",
    fontWeight: 500,
    lineHeight: "1.14",
    letterSpacing: "1.08px",
    paddingLeft: "5px",
  },

  addBtn:{
    backgroundColor: "#fff",
    color: "#000",
    height: "40px",
    width: "79px",
    padding: "5px 23px",
    border: "1px solid #dfdfdf",
    borderRadius: "5px",
    fontWeight: 500,
    fontSize: "16px!important",
    boxSizing: "border-box" as "border-box",
    "&:hover":{
      transition: ".5s ease",
      backgroundColor: "#364f6b",
      color: "#fff",
    }
  },

  quantityDiv:{
    color: "#000",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    "& button":{
      padding: 0,
      cursor: "pointer",
      fontSize: "1.25em",
      textIndent: "-100px",
      overflow: "hidden",
      position: "relative",
      color: "inherit",
      fontWeight: 400,
      border: "none",
      display: "inline-block",
      minWidth: "0",
      lineHeight: "1",
      "&:focus":{
        outline: "none"
      }
    },
    "& .qty-count--minus":{
      height: "30px",
      width: "30px",
      borderRight: "1px solid #e2e2e2",
      background: "#dfdfdf",
      borderRadius: "5px 0 0 5px",
      "&:after, &:before":{
        content: '""',
        height: "2px",
        width: "10px",
        position: "absolute",
        display: "block",
        background: "#757575",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
        fontWeight: 400,
        fontSize: "24px",
      }
    },
    "& .qty-count--add":{
      height: "30px",
      width: "30px",
      borderLeft: "1px solid #e2e2e2",
      background: "#3fc1cb",
      borderRadius: "5px 0 0 5px",
      transform: "rotate(-180deg)",
      "&:after, &:before":{
        content: '""',
        height: "2px",
        width: "10px",
        position: "absolute",
        display: "block",
        background: "#fff",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
      },
      "&:after":{
        transform: "rotate(90deg)"
      }
    }
  },

  productQuantity:{
    width: "38px",
    height: "30px",
    minWidth: "0",
    display: "inline-block",
    border: "1px solid #dfdfdf",
    textAlign: "center" as "center",
    WebkitAppearance: "textfield" as "textfield",
    MozAppearance: "textfield" as "textfield",
    appearance: "textfield" as "textfield",
    background: "transparent",
    color: "inherit",
    fontWeight: 400,
    fontSize: "inherit",
    lineHeight: "1",
  },

  productImage:{
    position: "relative" as "relative",
    "& .wishlistDiv":{
      position: "absolute",
      top: "5px",
      right: "5px",
      cursor: "pointer",
      "@media (max-width: 500px)":{
        position: "absolute",
        top: "3px",
        right: "0",
      },
      "& img":{
        float: "right",
        objectFit: "contain",
        maxWidth: "100%",
        height: "auto",
        "&:hover":{
          transition: "all .3s ease-in-out",
          transform: "scale(1.1)",
        }
      }
    }
  },

  imageStyle:{
      border: "1px solid rgb(0, 0, 0)",
      objectFit: "cover" as "cover",
      borderRadius: "5px",
      maxWidth: "100%",
      height: "auto",
  },

  loaderDiv:{
    objectFit: "cover" as "cover",
    height: "107.5px",
    width:"107.5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    position: "relative" as "relative",
    overflow: "hidden"
  },

  circularProgress:{
    position : "absolute" as "absolute", 
    top: "0", 
    left: "0", 
    right: "0", 
    bottom: "0", 
    margin: "auto"
  },

  loadMoreOption:{
    textAlign: "center" as "center",
    padding: "30px",
    "& span":{
      cursor: "pointer",
      fontSize: "20px",
      transition: "letter-spacing .3s ease-in-out",
      "&:hover":{
        letterSpacing: "1px",
        "& svg":{
          opacity:"1"
        }
      },
      "& svg":{
        color: "#f67054",
        transition: "opacity .5s ease-in-out",
        opacity: "0"
      }
    }
  },

  noResultsFoundDiv:{
    height: "557px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  noResult:{
    fontSize: "24px",
    fontWeight: 500,
    marginTop: "30px",
  },

  noResultTitle:{
    fontSize: "18px",
    fontWeight: 400,
    color: "#83889e",
  },

  skeletonListWrap:{
    padding: "24px",
    position: "relative" as "relative",
    minHeight: "150px",
    marginBottom: "24px",
    "--bs-bg-opacity": "1",
    backgroundColor: "rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important",
    boxShadow: "0px 4px 4px #f5f5f5 !important",
    borderRadius: "5px",
    "@media (min-width: 1900px)":{
      padding: "24px"
    },
    "@media (min-width: 1366px) and (max-width: 1900px)":{
      padding: "24px",
      marginBottom: "24px !important",
    },
    "@media (max-width: 1279.92px)":{
      padding: "15px",
      marginBottom: "10px",
    },
    "@media (max-width: 768px)":{
      padding: "15px",
      marginBottom: "10px",
      marginTop: "10px",
    },
    "@media (max-width: 500px)":{
      padding: "8px",
      marginBottom: "10px",
      marginTop: "10px",
    }
  },

  modalCss:{
    "@media (min-width: 768px)":{
      maxWidth: "600px"
    },
    "@media (max-width: 767.92px)":{
      maxWidth: "335px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& .modal-footer":{
      display: "inline-block!important"
    }
  },

  modalHeader:{
    borderColor: "#dbdbdb",
    borderWidth: "2px",
    padding: "1.5rem!important",
    "& span":{
      width: "100%",
      left: "0",
      right: "0",
      textAlign: "center",
      margin: "0!important",
      top: "27px",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "1.11",
      color: "var(--color-black)",
      "@media (max-width: 1279.92px)":{
        fontSize: "18px",
        lineHeight: "1.11",
      },
      "@media (max-width: 768px)":{
        fontSize: "18px",
        lineHeight: "1.11",
      }
    }
  },

  modalBody:{
    padding: "0!important",
    "& .ad-addr-body-wrap":{
      "@media (min-width: 1366px) and (max-width: 1900px)":{
        padding: "8px 0"
      },
      "& .modal-food-mainhead":{
        padding: "15px 25px",
        display: "flex",
        "& .modal-food-heading":{
          paddingLeft: "5px",
          lineHeight: ".1",
          textTransform: "capitalize",
          "& h3":{
            color: "var(--color-black)"
          }
        },

        "& .veg-nonVeg":{
          marginLeft: "1rem!important",
          padding: "0.25rem!important",
          "& img":{
            maxWidth: "100%",
            height: "auto",
          },
          "& span":{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "16px",
            color: "#000",
          }
        }
      }
    }
  },

  modalFormCss:{
    paddingTop: "20px",
    "& .modal-attributes-section":{
      padding: "15px 25px",
      "& .topping-span":{
        fontSize: "15px!important"
      },
      "& .form-group":{
        "@media (min-width: 1366px) and (max-width: 1900px)":{
          marginBottom: "15px"
        },
        "&:last-child":{
          marginBottom: "0!important"
        },
        "& .form-check-input":{
          float: "right"
        }
      }
    }
  },

  modalInputLable:{
    fontSize: "15px!important",
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2 !important",
    letterSpacing: "normal",
    textAlign: "left" as "left",
    color: "#8b8f95",
    margin: "25px 0 0",
  },

  modalDivider:{
    padding: "0.5px",
    backgroundImage: "linear-gradient(to right, #dbdbdb 0%, #dbdbdb 50%, transparent 50%)",
    backgroundSize: "10px 1px",
    "& .item-total":{
      float: "left",
      "& span":{
        fontSize: "15px",
        color: "var(--color-orange)",
      },
      "& .item-total-flex":{
        display: "flex",
        flexDirection: "row",
        "& div":{
          fontSize: "20px",
          color: "var(--color-RegularActiveText)",
        },
        "& span":{
          fontSize: "12px",
          paddingTop: "6%",
          color: "var(--color-dark-grey)",
          marginLeft: "4px",
        }
      }
    }
  },

  addToCartBtn:{
    padding: "10px",
    borderRadius: "5px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    float: "right" as "right",
    height: "45px",
    width: "45%",
    fontSize: "18px!important",
    border: "none!important",
    backgroundColor: "var(--button-primary)!important",
    color: "var(--button-text-primary)!important",
    "&:hover":{
      transition: ".3s ease",
      backgroundColor: "var(--button-primary-hover)!important",
      color: "var(--button-text-primary-hover)!important",
    }
  }
}
// Customizable Area End

class FilterProducts extends FilterProductController {
  // Customizable Area Start

  // Coupon Area Start
  couponCodeModal = () => {
    return <Modal
      className={this.props?.classes?.modalCss}
      isOpen={this.state && this.state.showAddToCart}
      toggle={() => this.toggleAddToCartModal("random")}
    >
      <ModalHeader
        className={this.props?.classes?.modalHeader}
        close={
          <img
          alt="close-btn"
          data-testid="modal-header-cart"
            style={{cursor: "pointer"}}
            src={modalCloseIcon}
            onClick={() => this.toggleAddToCartModal("random")}
          />
        }
      >
        <span>{content.customize}</span>
      </ModalHeader>
      <ModalBody className={this.props?.classes?.modalBody}>
        <div className="ad-addr-body-wrap">
          <div className="modal-food-mainhead">
            <div className="modal-food-heading">
              <h3>{this.state.cartProduct?.attributes?.name}</h3>
            </div>
            <div className="veg-nonVeg">
              <img
                src={
                  this.state.cartProduct?.attributes?.non_veg
                    ? FoodTagNonVeg
                    : FoodTagVeg
                }
              />
              {this.state.cartProduct?.attributes?.non_veg ? (
                <span>&nbsp;&nbsp;Non-vegetarian</span>
              ) : (
                <span>&nbsp;&nbsp;Vegetarian</span>
              )}
            </div>
          </div>
          <Formik
            innerRef={this.formRef}
            initialValues={{}}
            data-testid="attribute-form"
            validationSchema={() => {
              return Yup.object().shape({
                radioButton: Yup.bool().oneOf(
                  [true],
                  "Accept Terms & Conditions is required"
                ),
              });
            }}
            onSubmit={(values) => {
              this.calculateCartTotal(values);
            }}
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  className={this.props?.classes?.modalFormCss}
                >
                  {this.state.cartProduct?.attributes
                    ?.catalogue_catalogue_attributes.length > 0 && (
                    <>
                      {this.state.cartProduct?.attributes?.catalogue_catalogue_attributes.map(
                        (attributes, index: number) => (
                          <div key={index}>
                            <div
                              className="modal-attributes-section"
                            >
                              <span className="topping-span">{attributes?.attributes?.name}</span>
                              {attributes?.attributes
                                ?.catalogue_catalogue_attribute_values
                                ?.length > 0 &&
                                attributes?.attributes?.catalogue_catalogue_attribute_values?.map(
                                  (microAttribute, index: number) => (
                                    <FormGroup>
                                      <span className={this.props?.classes?.modalInputLable}>
                                        {microAttribute?.attributes?.name}
                                      </span>
                                      <Field
                                        value={`${microAttribute?.attributes?.price},${microAttribute?.id}`}
                                        type={
                                          attributes?.attributes
                                            ?.control_type ===
                                          "radio_button"
                                            ? "radio"
                                            : "checkbox"
                                        }
                                        data-testid="attributes"
                                        name={attributes?.attributes?.name}
                                        id={microAttribute?.id}
                                        className="form-check-input"
                                        onClick={() =>
                                          this.formRef?.current?.handleSubmit()
                                        }
                                      />
                                    </FormGroup>
                                  )
                                )}
                            </div>
                            {this.state.cartProduct?.attributes
                              ?.catalogue_catalogue_attributes.length -
                              1 !==
                              index && (
                              <div className={this.props?.classes?.modalDivider} />
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="item-total">
          <span>{content.itemTotal}</span>
          <div className="item-total-flex">
            <div>
              {this.state.cartTotal === 0 ? (
                <>
                  {showTwoDecimalDigit(
                    this.state.cartProduct?.attributes?.on_sale
                      ? this.state.cartProduct?.attributes?.sale_price ?? 0
                      : this.state.cartProduct?.attributes?.price ?? 0
                  )}
                </>
              ) : (
                this.state.cartTotal
              )}
            </div>
            <span>+{content.taxes}</span>
          </div>
        </div>
        <Button
          className={this.props?.classes?.addToCartBtn}
          onClick={() => this.addToCart("Modal")}
          data-testid="modalAddToCartId"
        >
          {content.addItem}
        </Button>
      </ModalFooter>
    </Modal>
  }

  // foodTypes
  foodType = (product:CatalogueType) => {
    return <div className={this.props?.classes?.vegNonVegDiv}>
              <img
                src={
                  product?.attributes?.non_veg
                    ? FoodTagNonVeg
                    : FoodTagVeg
                }
              />
              {product?.attributes?.non_veg ? (
                <span>&nbsp;&nbsp;Non-vegetarian</span>
              ) : (
                <span>&nbsp;&nbsp;vegetarian</span>
              )}
            </div>
  }

  //cart quantity 
  cartQtyBlock = (product:CatalogueType) => {
    return product?.attributes?.availability ===
      "in_stock" ? (
        <>
          {product?.attributes?.cart_quantity === null ? (
            <button
              data-testid="cartQuantityId"
              className={this.props?.classes?.addBtn}
              onClick={() => {
                product?.attributes
                  ?.catalogue_catalogue_attributes
                  .length > 0
                  ? this.toggleAddToCartModal(product)
                  : this.addToCart(product);
              }}
            >
              Add
            </button>
          ) : (
            <>
              {product?.attributes
                ?.catalogue_catalogue_attributes.length >
              0 ? (
                <button
                  className={this.props?.classes?.addBtn}
                  data-testid="productAttribute"
                  onClick={() => {
                    this.toggleAddToCartModal(product);
                  }}
                >
                  Add
                </button>
              ) : (
                <>
                  <div className={this.props?.classes?.quantityDiv}>
                    <button
                      className="qty-count--minus"
                      data-action="minus"
                      data-testid="decrease"
                      type="button"
                      onClick={() => {
                        if (
                          Number(product?.attributes
                            ?.cart_quantity) +
                            this.state.itemQuantity -
                            1 <
                          1
                        ) {
                          this.setState({
                            isCartItemRemove: true,
                            cartItemId: product?.id,
                          });
                        } else {
                          this.updateCartQuantity(
                            product,
                            "Subtract"
                          );
                        }
                      }}
                    >
                      -
                    </button>
                    <input
                      className={this.props?.classes?.productQuantity}
                      type="number"
                      name="product-qty"
                      min="0"
                      max="10"
                      value={
                        product?.attributes?.cart_quantity
                      }
                    />
                    <button
                      className="qty-count--add"
                      data-action="add"
                      data-testid="increase"
                      type="button"
                      onClick={() => {
                        if (
                          Number(product?.attributes
                            ?.cart_quantity) +
                            this.state.itemQuantity +
                            1 >
                            Number(product?.attributes
                              ?.cart_limit) &&
                          product?.attributes?.cart_limit
                        ) {
                          warnNotification(
                            `You can not add more than ${product?.attributes?.cart_limit} quantity of this product`
                          );
                        } else {
                          this.updateCartQuantity(
                            product,
                            "Add"
                          );
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <span className={this.props?.classes?.outOfStock}>
          {content.notAvailable}
        </span>
      );
  }

  // Product Item row
  productItem = (product:CatalogueType, index:number) => {
    return <div
      className={this.props?.classes?.productList}
      key={index}
      data-testid="productListId"
    >
      <div className="row">
        <div className="col-xl-10 col-lg-10 col d-flex flex-column justify-content-between">
          <div className={`${this.props?.classes?.cartProductInfo} w3-ripple`}>
            <h2>
              {product?.attributes?.name?.toLowerCase()}
            </h2>
            {product?.attributes?.description && (
              <ShowMoreText
              // Default options
                lines={2}
                more="Show more"
                less="Show less"
                className="food-subtitle"
                anchorClass="show-more-text"
                expanded={false}
                width={350}
                truncatedEndingComponent={"... "}
              >
                <p className="food-subtitle">
                  {product?.attributes?.description}
                </p>
              </ShowMoreText>
            )}

            <div className="d-flex">
              {this.foodType(product)}
            </div>

            
          </div>
          <div className="d-flex align-items-center pl-1 justify-content-between">
            <div>
              {product?.attributes?.availability === "in_stock" && (
                <>
                  {product?.attributes?.on_sale ? (
                    <>
                      <span className={this.props?.classes?.productPrice}>
                        {showTwoDecimalDigit(
                          product?.attributes?.sale_price ?? 0
                        )}
                      </span>
                      <span className={this.props?.classes?.productActualPrice}>
                        {showTwoDecimalDigit(
                          product?.attributes?.price
                        )}
                      </span>
                      <span className={this.props?.classes?.productDiscount}>
                        save{" "}
                        {`${Number(product?.attributes?.discount ?? 0).toFixed(2)}`}
                        %
                      </span>
                    </>
                  ) : (
                    <span className={this.props?.classes?.productPrice}>
                      {showTwoDecimalDigit(
                        product?.attributes?.price
                      )}
                    </span>
                  )}
                </>
              )}
            </div>
            <div>
              <>
                {this.cartQtyBlock(product)}
              </>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-lg-2 col mt-1 d-flex align-items-start">
          <div className={`${this.props?.classes?.productImage} w3-ripple`}>
            <div
              data-testid="wishListId"
              className="wishlistDiv"
              onClick={() =>
                product?.attributes?.wishlisted
                  ? this.removeWishlist(Number(product?.id))
                  : this.addToWishlist(Number(product?.id))
              }
            >
              <img
                src={
                  product?.attributes?.wishlisted
                    ? Wishlisted
                    : NotWishlisted
                }
                alt="product_image"
              />
            </div>
            {this.props?.myLoader || this.state.loader ? 
              <div className={this.props?.classes?.loaderDiv}>
                <img
                  src={
                    product?.attributes?.images?.data[0]?.attributes
                      ?.url
                  }
                  className={this.props?.classes?.imageStyle}/>
                  <CircularProgress color="inherit" className={this.props?.classes?.circularProgress}/>
                </div>
              :
            <img
              src={
                product?.attributes?.images?.data[0]?.attributes
                  ?.url
              }
              className={this.props?.classes?.imageStyle}
            />}
            </div>
        </div>
      </div>
    </div>
  }
  
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <div className={classes.mainWrapper}>
        {this.props?.myProductsList?.catalogues?.length > 0 ? (
          <>
            {this.props?.myProductsList?.catalogues?.map(
              (product, index: number) => (
                this.productItem(product, index)
              )
            )}
          </>
        ) : (
          <>
            {this.props?.myLoader || this.state.loader ? (
              new Array(3).fill(0).map((items: { id : number}, index: number) => (
                <div
                  key={index}
                  data-testid="skeletonLoader"
                  className={classes.skeletonListWrap}
                >
                  <ListSkeletonLoader />
                </div>
              ))
            ) : (
              <div className={`p-3 bg-white radius-10 mb-4 m-auto ${classes.noResultsFoundDiv}`} data-testid="noProductsFound">
                <div className="text-center">
                  <img src={noResultFound} alt="no-result" />
                  <p className={classes.noResult}>No Results Found !</p>
                  <p className={classes.noResultTitle}>
                    Try modifying your search to get relevant results.
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        {this.props?.myProductsList?.meta?.pagination?.next_page !== null ? (
          <div className={classes.loadMoreOption}>
            <span
              data-testid="loadMoreButtonId"
              onClick={() => this.props.updateProductsQuantity("Data")}
            >
              {!this.props?.myLoader && (
                <>
                  Load More{" "}
                  <AiOutlineDoubleRight/>
                </>
              )}
            </span>
          </div>
        ) : (
          <>
            {this.props?.myProductsList?.catalogues?.length > 6 && (
              <div className={classes.loadMoreOption}>
                <span
                  data-testid = "scrollToTop"
                  onClick= {this.scrollToTopClick}
                >
                  Scroll To Top{" "}
                  <MdKeyboardArrowUp/>
                </span>
              </div>
            )}
          </>
        )}
        {this.couponCodeModal()}

        {this.state.isCartItemRemove && (
          <ConfirmActionModel
            closeConfirmActionModel={this.removeProductModalClose}
            onHandleBtnAction={this.removeProductFromCart}
            isOpenConfirmActionModel={this.state.isCartItemRemove}
            modelHeaderText={content.removeProduct}
            modelBodyText={content.removeProductTagline}
            btnActionText={content.yesDelete}
            btnCancelText={content.cancelBtn}
          />
        )}
      </div>
    );
    // Customizable Area End
  }
}

export default withRouter(withStyles(useStyles)(FilterProducts));
export { FilterProducts };
// Customizable Area Start
// Customizable Area End
