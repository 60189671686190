import { RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  getLocalState,
  setLocalState,
} from "../../studio-store-restaurant-components/src/Utility.web";
import { OrderResponseType, ShoppingCartResponseType } from "../../utilities/src/types/ShoppingCartTypes";
import { BannerImage, DashboardBannerResponseType, FilterMyProductListType, LoadCategoryResponseType, ProductListResponseType } from "../../utilities/src/types/DashboardTypes";
import { APIInvalidOtpResponseType, ItemsCategoryType } from "../../utilities/src/types/CommonTypes";
import { BannerResponseType, APIValidResponseType, APIInvalidResponseType, ImageResponseType } from "../../utilities/src/types/CommonTypes";

export const configJSON = require("./config.js");

// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNonVeg: boolean;
  isVeg: boolean;
  loader: boolean;
  itemQuantity: number;
  productList: FilterMyProductListType;
  categoryList: ItemsCategoryType[];
  cartList: OrderResponseType;
  bannerImgList: BannerImage[];
  numberOfProduct: number;
  isFavorites: boolean;
  categoryId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllProductAPICallId: string = "";
  getCategoriesAPICallId: string = "";
  getCartAPICallId: string = "";
  getBannersApiCallId: string = "";
  sendDeviceTokenApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    let isShowSearchBar = new Message(
      getName(MessageEnum.ShowSearchBarApiMessage)
    );
    isShowSearchBar.addData(
      getName(MessageEnum.ShowSearchBarApiResponseMessage),
      true
    );
    runEngine.sendMessage(isShowSearchBar.id, isShowSearchBar);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      loader: false,
      isVeg: false,
      productList: {} as FilterMyProductListType,
      categoryList: [],
      bannerImgList: [] as BannerImage[] ,
      cartList: {} as OrderResponseType,
      itemQuantity: 0,
      numberOfProduct: 10,
      isNonVeg: false,
      isFavorites: false,
      categoryId: 0,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getBannersImgList();
    this.onFcmRegister();
    this.getCategoryList();
    this.getCartList();
    if (this.state.categoryId > 0) {
      this.getProductList();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentWillReceiveProps(nextProps: Props) {
    this.getProductList();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const userData = getLocalState("userData");
    let updateUserInfoMessage = new Message(
      getName(MessageEnum.UserInfoApiMessage)
    );
    updateUserInfoMessage.addData(
      getName(MessageEnum.UserInfoApiResponseMessage),
      userData
    );
    runEngine.sendMessage(updateUserInfoMessage.id, updateUserInfoMessage);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.callBackResponse(apiRequestCallID, responseJSON);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  callBackResponse = (apiRequestCallID:string, responseJSON: DashboardBannerResponseType & APIValidResponseType & APIInvalidResponseType & ProductListResponseType & APIInvalidOtpResponseType & OrderResponseType & ShoppingCartResponseType & LoadCategoryResponseType) => {
    if (this.isValidResponse(responseJSON)) {
      this.apiSuccessCallBacks(apiRequestCallID, responseJSON);
    }
    if (this.isInValidResponse(responseJSON)) {
        this.apiFailCallBack(apiRequestCallID, responseJSON);
    }
  }

  apiFailCallBack = (apiRequestCallID:string ,responseJSON:APIInvalidOtpResponseType) => {
    if (apiRequestCallID !== null && apiRequestCallID === this.getCartAPICallId) {
      if (responseJSON.errors[0].token == "Token has Expired") {
        window.localStorage.clear();
        this.props?.history?.push({
          pathname: "/login",
          state: { activeTab: "2" },
        });
      }
    }
  }

  isValidResponse = (responseJson: APIValidResponseType) => {
    return responseJson && responseJson.data;
  }

  isInValidResponse = (responseJSON: APIInvalidResponseType) => {
    return responseJSON && responseJSON.errors && responseJSON.errors.length > 0;
  }

  apiSuccessCallBacks = (apiRequestCallID: string, responseJSON: ShoppingCartResponseType & ProductListResponseType & DashboardBannerResponseType & LoadCategoryResponseType) => {
    
    if (apiRequestCallID === this.getAllProductAPICallId) {
      this.LoadProduct(responseJSON);
    }
    
    if (apiRequestCallID === this.getBannersApiCallId) {
      this.bannerImgListResponce(responseJSON.data?.banners?.data);
      let bannerListData = new Message(
        getName(MessageEnum.BannerListApiMessage)
      );
      bannerListData.addData(
        getName(MessageEnum.BannerListApiResponseMessage),
        responseJSON.data?.banners?.data
      );
      runEngine.sendMessage(bannerListData.id, bannerListData);
    }

    if (apiRequestCallID === this.getCategoriesAPICallId) {
      this.loadCategory(responseJSON);
    }

    if (apiRequestCallID === this.getCartAPICallId) {
      
      setLocalState("cartId", responseJSON.data?.order?.data?.id);
      setLocalState(
        "cartDataLen",
        responseJSON?.data?.order?.data?.attributes?.order_items?.length
      );
      const cartListUpdateMessage = new Message(
        getName(MessageEnum.UpdateCartListApiMessage)
      );
      cartListUpdateMessage.addData(
        getName(MessageEnum.UpdateCartListApiResponseMessage),
        responseJSON?.data?.order?.data?.attributes?.order_items?.length
      );
      runEngine.sendMessage(
        cartListUpdateMessage.id,
        cartListUpdateMessage
      );
      this.cartItemLoad(responseJSON);
    }
  }

  cartItemLoad = (responseJSON: ShoppingCartResponseType) => {
    this.setState({
      loader: false,
      cartList: responseJSON.data,
    });
    if (responseJSON.data?.order) {
      const { data } = responseJSON.data?.order;
      setLocalState(
        "isCartHaveItems",
        data?.attributes?.order_items?.length > 0 && true
      );
    }
  }

  loadCategory = (responseJSON:LoadCategoryResponseType) => {
    this.setState({
      loader: false,
      categoryList: responseJSON.data,
    });
    if (responseJSON.data) {
      this.setState({
        categoryId:
          responseJSON.data && responseJSON.data[0]?.attributes?.id,
      });
      this.props.history.push(
        `/?category_id=${
          responseJSON.data && responseJSON.data[0]?.attributes?.id
        }`
      );
    }
  }

  LoadProduct = (responseJSON:ProductListResponseType) => {
    this.setState({
      loader: false,
      productList: responseJSON.data,
    });
    if (this.state.isFavorites) {
      const result = responseJSON.data?.catalogues?.filter(
        (element) => {
          if (element?.attributes?.wishlisted) {
            return element;
          }
        }
      );
      const finalArray = {
        catalogues: [...result],
        meta: responseJSON.data.meta,
      };
      this.setState({
        productList: finalArray,
      });
    }
  }
  bannerImgListResponce = (apiResData: BannerResponseType[]) => {
    const bannerDataImg = apiResData
      .filter(
        (banner: { attributes: { banner_position: number } }) =>
          banner?.attributes?.banner_position === 1
      )
      ?.map(
        (banner: { attributes: { images: {data: ImageResponseType[]} } }) => banner?.attributes?.images
      );
    let bannerList = [];
    for (let bannerLength = 0; bannerLength < bannerDataImg?.length; bannerLength++) {
      for (let imageLength = 0; imageLength < bannerDataImg[bannerLength]?.data?.length; imageLength++) {
        if (bannerDataImg[bannerLength]?.data[imageLength]?.attributes) {
          bannerList.push(bannerDataImg[bannerLength]?.data[imageLength]?.attributes);
        }
      }
    }
    this.setState({ bannerImgList: bannerList });
  };

  productsQuantity = (value: string) => {
    this.setState({ numberOfProduct: this.state.numberOfProduct + 10 });
    setTimeout(() => {
      this.getProductList();
    }, 300);
  };

  // get product list
  getProductList = async () => {
    this.setState({
      loader: true,
      itemQuantity: 0,
    });
    let apiUrl: string;
    const urlSearch = new URLSearchParams(this.props.history.location.search);
    if (window.location.search === "" || urlSearch.get("q[name]") !== null) {
      if (window.location.search === "") {
        apiUrl = `${configJSON.getAllProductsAPIEndPoint}?per_page=${this.state.numberOfProduct}`;
      } else {
        apiUrl = `${
          configJSON.getSearchBlockAPIEndpoint
        }?q[name]=${urlSearch.get("q[name]")}&per_page=${
          this.state.numberOfProduct
        }`;
      }
    } else {
      apiUrl = `${configJSON.getAllProductsAPIEndPoint}${window.location.search}&per_page=${this.state.numberOfProduct}`;
    }
    if (apiUrl) {
      this.getAllProductAPICallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiGetMethod,
        endPoint: apiUrl,
      });
    }
  };

  getCategoryList = async () => {
    this.setState({
      loader: true,
    });

    this.getCategoriesAPICallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCategoriesAndProductsListAPIEndPoint,
    });
  };

  getBannersImgList = async () => {
    this.setState({
      loader: true,
    });
    this.getBannersApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getWebBannersAPIEndpoint,
    });
  };

  getCartList = async () => {
    this.setState({
      loader: true,
    });
    this.getCartAPICallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCartBlockAPIEndPoint,
    });
  };
  // Customizable Area End

  onFcmRegister = async () => {
    let fcmToken = await getLocalState("fcmToken");
    let record_data = {
      data: {
        attributes: {
          token: fcmToken,
          platform: "web",
        },
      },
    };
    this.sendDeviceTokenApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.sendDeviceTokenAPiEndPoint,
      body: record_data,
    });
  };

  sendDeviceTokenSuccessCallBack = (response: APIValidResponseType) => {
    
  };
}
// Customizable Area Start
// Customizable Area End
