import React, { RefObject } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  getLocalState,
  setLocalState,
  showTwoDecimalDigit,
  successNotification,
} from "../../studio-store-restaurant-components/src/Utility.web";
import { FormikProps } from "formik";
import { APIValidResponseType, CatalogueType, ItemsCategoryType } from "../../utilities/src/types/CommonTypes";
import { FilterMyProductListType } from "../../utilities/src/types/DashboardTypes";
export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  myCategory: ItemsCategoryType[];
  getProducts: () => void;
  getCarts: () => void;
  myLoader: boolean;
  myProductsList: FilterMyProductListType;
  updateProductsQuantity: (val: string) => void;
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean;
  isVeg: boolean;
  itemQuantity: number;
  showAddToCart: boolean;
  cartProduct: CatalogueType;
  cartTotal: number | string;
  cartIds:  { catalogue_attribute_value_id: string | number }[];
  cartItemId: string | number;
  isCartItemRemove: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class FilterProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postWishlistApiCallId: string = "";
  removeWishlistApiCallId: string = "";
  addToCartApiCallId: string = "";
  updateCartApiCallId: string = "";
  removeCartItemsApiCallId: string = "";
  formRef: RefObject<FormikProps<{}>>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.UpdateWishlist),
      getName(MessageEnum.UpdateCartListApiMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      loader: false,
      showAddToCart: false,
      isVeg: false,
      cartProduct: {} as CatalogueType,
      cartTotal: 0,
      itemQuantity: 0,
      cartIds: [],
      cartItemId: 0,
      isCartItemRemove: false
      // Customizable Area End
    };
    this.formRef = React.createRef();
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.apiMainCallBack(apiRequestCallID, responseJSON, errorMessage);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiMainCallBack = (apiRequestCallID:string, responseJSON:APIValidResponseType, errorMessage:string = "") => {
    if (this.isValidResponse(responseJSON)) {
      if (apiRequestCallID !== null) {
        this.apiSuccessCallBacks(apiRequestCallID, responseJSON);
      }
    }
    if (errorMessage) {
      this.setState({ loader: false });
    }
  }
  isValidResponse = (responseJson: APIValidResponseType) => {
    return responseJson && responseJson.data;
  }

  removeProductModalClose = () => {
    this.setState({
      isCartItemRemove: !this.state.isCartItemRemove,
    });
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJSON: APIValidResponseType) => {
    this.setState({ itemQuantity: 0 });
    //create wishlist
    if (apiRequestCallID === this.postWishlistApiCallId) {
      successNotification(responseJSON?.message ?? "Product has been Added To Wishlist");
      const wishlist_length = parseInt(
        getLocalState("wishlist_len") ?? "0"
      );
      setLocalState("wishlist_len", wishlist_length + 1);
      let wishlistUpdateMessage = new Message(
        getName(MessageEnum.UpdateWishlist)
      );
      wishlistUpdateMessage.addData(
        getName(MessageEnum.UpdateWishlistLen),
        wishlist_length + 1
      );
      runEngine.sendMessage(
        wishlistUpdateMessage.id,
        wishlistUpdateMessage
      );
      this.props?.getProducts();
    }
    //delete wishlist
    if (apiRequestCallID === this.removeWishlistApiCallId) {
      successNotification(responseJSON?.message ?? "Product has been Removed From Wishlist");
      const wishlist_length = parseInt(
        getLocalState("wishlist_len") ?? "0"
      );
      setLocalState("wishlist_len", wishlist_length - 1);
      let wishlistUpdateMessage = new Message(
        getName(MessageEnum.UpdateWishlist)
      );
      wishlistUpdateMessage.addData(
        getName(MessageEnum.UpdateWishlistLen),
        wishlist_length - 1
      );
      runEngine.sendMessage(
        wishlistUpdateMessage.id,
        wishlistUpdateMessage
      );
      this.props?.getProducts();
    }
    //add to cart
    if (apiRequestCallID === this.addToCartApiCallId) {
      successNotification("Product has been Added To Cart");

      const cartDataLength = parseInt(
        getLocalState("cartDataLen") ?? "0"
      );

      setLocalState("cartDataLen", cartDataLength + 1);
      let cartListUpdateMessage = new Message(
        getName(MessageEnum.UpdateCartListApiMessage)
      );
      cartListUpdateMessage.addData(
        getName(MessageEnum.UpdateCartListApiResponseMessage),
        cartDataLength + 1
      );
      runEngine.sendMessage(
        cartListUpdateMessage.id,
        cartListUpdateMessage
      );
      this.props?.getProducts();
      this.props?.getCarts();
    }
    //update cart
    if (apiRequestCallID === this.updateCartApiCallId) {
      successNotification("Cart has been Updated.");
      this.props?.getProducts();
      this.props?.getCarts();
    }
    //update cart
    if (apiRequestCallID === this.removeCartItemsApiCallId) {
      this.setState({ itemQuantity: 0, isCartItemRemove: false });
      successNotification("Items Remove from cart.");
      const cartDataLength = parseInt(
        getLocalState("cartDataLen") ?? "0"
      );
      setLocalState("cartDataLen", cartDataLength - 1);
      let cartListUpdateMessage = new Message(
        getName(MessageEnum.UpdateCartListApiMessage)
      );
      cartListUpdateMessage.addData(
        getName(MessageEnum.UpdateCartListApiResponseMessage),
        cartDataLength - 1
      );
      runEngine.sendMessage(
        cartListUpdateMessage.id,
        cartListUpdateMessage
      );
      this.props?.getProducts();
      this.props?.getCarts();
    }
  }

  //Add To Wishlist
  addToWishlist = async (catalogue_id: number) => {
    const httpBody = {
      catalogue_id: catalogue_id,
    };

    this.postWishlistApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.postWishlistAPIEndpoint,
      body: httpBody,
    });
  };

  //Remove from Wishlist
  removeWishlist = async (catalogue_id: number) => {
    this.removeWishlistApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.delWishlistAPIEndpoint}${catalogue_id}`,
    });
  };

  //Toggle Add To Cart modal
  toggleAddToCartModal = (catalogue: any): void => {
    this.setState({
      showAddToCart: !this.state.showAddToCart,
      cartProduct: catalogue,
      cartTotal: 0,
      cartIds: [],
    });
  };

  //Calculate Cart Total & populate subs ids
  calculateCartTotal = (catalogue: any): void => {
    let productPrice = this.state.cartProduct?.attributes?.on_sale
      ? this.state.cartProduct?.attributes?.sale_price ?? 0
      : this.state.cartProduct?.attributes?.price ?? 0;
    let sampleArray = [];
    let totalSum: string | number = productPrice;
    let idStorage:{ catalogue_attribute_value_id: string | number }[] = [];
    for (let keys in catalogue) {
      if (catalogue.hasOwnProperty(keys)) {
        const values = catalogue[keys];
        sampleArray.push(values);
      }
    }
    sampleArray.forEach((content) => {
      if (typeof content === "string") {
        const [sumVariable, idVariable] = content.split(",");
        totalSum = parseFloat(totalSum as string) + parseFloat(sumVariable);
        idStorage.push({ catalogue_attribute_value_id: idVariable });
      } else if (typeof content === "object") {
        content.forEach((myData: string) => {
          const [sumVariable, idVariable] = myData.split(",");
          totalSum = parseFloat(totalSum as string) + parseFloat(sumVariable);
          idStorage.push({ catalogue_attribute_value_id: idVariable });
        });
      }
    });
    this.setState({
      cartTotal: showTwoDecimalDigit(totalSum),
      cartIds: idStorage,
    });
  };

  //Add To Cart
  addToCart = async (type: any) => {
    this.setState({ showAddToCart: false });

    let httpBody: {
      catalogue_id: number;
      quantity: number;
      order_item_options_attributes?: Array<{ catalogue_attribute_value_id: string | number }>;
    };
    if (type === "Modal") {
      httpBody = {
        catalogue_id: parseInt(this.state.cartProduct?.id),
        quantity: 1,
        order_item_options_attributes: this.state.cartIds,
      };
    } else {
      httpBody = {
        catalogue_id: parseInt(type?.id),
        quantity: 1,
      };
    }

    this.addToCartApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.orderBlockAPIEndpoint,
      body: httpBody,
    });
  };

  //Update Cart
  updateCartQuantity = (product: CatalogueType, action: string): void => {
    if (action === "Add") {
      this.setState({
        itemQuantity:
          Number(product?.attributes?.cart_quantity) + this.state.itemQuantity + 1,
      });
    } else if (action === "Subtract") {
      this.setState({
        itemQuantity:
          Number(product?.attributes?.cart_quantity) + this.state.itemQuantity - 1,
      });
    }

    setTimeout(async () => {
      const httpBody = {
        catalogue_id: parseInt(product?.id),
        quantity: this.state.itemQuantity,
      };

      this.updateCartApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: configJSON.orderBlockAPIEndpoint,
        body: httpBody,
      });
    }, 200);
  };

  //remove from cart
  removeProductFromCart = async () => {
    this.setState({});

    const httpBody = {
      catalogue_id: this.state.cartItemId,
      quantity: 0,
    };

    this.removeCartItemsApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.orderBlockAPIEndpoint,
      body: httpBody,
    });
  };

  scrollToTopClick = () => {
    window.scrollTo({
      top: 320,
      left: 0,
      behavior: "smooth",
    })
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
